import React, { useState } from 'react';
import { View, Text, StyleSheet, Picker, ScrollView, TextInput } from 'react-native';
import CheckboxGroup from './Checkbox'; // Import the Checkbox component

const Filter = ({ filters, selectedFilters, onFilterChange }) => {
  const handleFilterToggle = (filter, value) => {
    const updatedFilters = { ...selectedFilters };

    if (!Array.isArray(updatedFilters[filter])) {
      updatedFilters[filter] = []; // Ensure the value is an array
    }

    const index = updatedFilters[filter].indexOf(value);

    if (index !== -1) {
      updatedFilters[filter].splice(index, 1); // Remove value if it exists
    } else {
      updatedFilters[filter].push(value); // Add value if it doesn't exist
    }

    onFilterChange(updatedFilters);
  };

  const handlePincodeChange = (filter, pincode) => {
    const updatedFilters = { ...selectedFilters, [filter]: pincode };
    onFilterChange(updatedFilters);
  };
  const handleMinChange = (text) => {
    console.log('Min Value:', text);
    const updatedFilters = {
      ...selectedFilters,
      min: text,
      commercialsFor1InstagramReel: `${text || ""}-${selectedFilters.max || ""}`,
    };
    onFilterChange(updatedFilters);
  };

  const handleMaxChange = (text) => {
    console.log('Max Value:', text);
    
    // Concatenate min and max to form commercialsFor1InstagramReel
    const updatedFilters = {
      ...selectedFilters,
      max: text,
      commercialsFor1InstagramReel: `${selectedFilters.min || ""}-${text || ""}`,
    };
  
    console.log('Updated Filters:', updatedFilters);
    onFilterChange(updatedFilters);
  };
  
  const handleMinChange1 = (text1) => {
    const updatedFilters = { ...selectedFilters, min1: text1,
      commercialsFor1InstagramStory: `${text1 || ""}-${selectedFilters.max1 || ""}`,
     };
    onFilterChange(updatedFilters);
  };

  const handleMaxChange1 = (text1) => {
    const updatedFilters = { ...selectedFilters, max1: text1,
      commercialsFor1InstagramStory: `${selectedFilters.min1 || ""}-${text1 || ""}`,
     };
    onFilterChange(updatedFilters);
  };
  const handleMinChange2 = (text2) => {
    const updatedFilters = { ...selectedFilters, min2: text2,
      commercialsFor1InstagramPost: `${text2 || ""}-${selectedFilters.max2 || ""}`,
     };
    onFilterChange(updatedFilters);
  };

  const handleMaxChange2 = (text2) => {
    const updatedFilters = { ...selectedFilters, max2: text2,
      commercialsFor1InstagramPost: `${selectedFilters.min2 || ""}-${text2 || ""}`, };
    onFilterChange(updatedFilters);
  };
  const handleMinChange3 = (text3) => {
    const updatedFilters = { ...selectedFilters, min3: text3,
      commercialsFor1YoutubeVideosDedicated: `${text3 || ""}-${selectedFilters.max3 || ""}`, 
    };
    onFilterChange(updatedFilters);
  };

  const handleMaxChange3 = (text3) => {
    const updatedFilters = { ...selectedFilters, max3: text3,
      commercialsFor1YoutubeVideosDedicated: `${selectedFilters.min3 || ""}-${text3 || ""}`,
     };
    onFilterChange(updatedFilters);
  };
  const handleMinChange4 = (text4) => {
    const updatedFilters = { ...selectedFilters, min4: text4,
      commercialsFor1YoutubeVideosIntegrated: `${text4 || ""}-${selectedFilters.max4 || ""}`,
     };
    onFilterChange(updatedFilters);
  };

  const handleMaxChange4 = (text4) => {
    const updatedFilters = { ...selectedFilters, max4: text4,
      commercialsFor1YoutubeVideosIntegrated: `${selectedFilters.min4 || ""}-${text4 || ""}`,
     };
    onFilterChange(updatedFilters);
  };
  const handleMinChange5 = (text5) => {
    const updatedFilters = { ...selectedFilters, min5: text5,
      commercialsFor1YoutubeVideosShortsDedicated: `${text5 || ""}-${selectedFilters.max5 || ""}`,
     };
    onFilterChange(updatedFilters);
  };

  const handleMaxChange5 = (text5) => {
    const updatedFilters = { ...selectedFilters, max5: text5,
      commercialsFor1YoutubeVideosShortsDedicated: `${selectedFilters.min5 || ""}-${text5 || ""}`,
     };
    onFilterChange(updatedFilters);
  };
  const handleMinChange6 = (text6) => {
    const updatedFilters = { ...selectedFilters, min6: text6,
      commercialsFor1YoutubeVideosShortsIntegrated: `${text6 || ""}-${selectedFilters.max6 || ""}`,
     };
    onFilterChange(updatedFilters);
  };

  const handleMaxChange6 = (text6) => {
    const updatedFilters = { ...selectedFilters, max6: text6,
      commercialsFor1YoutubeVideosShortsIntegrated: `${selectedFilters.min6 || ""}-${text6 || ""}`,
     };
    onFilterChange(updatedFilters);
  };
  const handleMinChange7 = (text7) => {
    const updatedFilters = { ...selectedFilters, min7: text7,
      followers: `${text7 || ""}-${selectedFilters.max7 || ""}`, 
    };
    onFilterChange(updatedFilters);
  };

  const handleMaxChange7 = (text7) => {
    const updatedFilters = { ...selectedFilters, max7: text7,
      followers: `${selectedFilters.min7 || ""}-${text7 || ""}`,
     };
    onFilterChange(updatedFilters);
  };
  const handleMinChange8 = (text8) => {
    const updatedFilters = { ...selectedFilters, min8: text8,
      youtubeSubscriber: `${text8 || ""}-${selectedFilters.max8 || ""}`,
     };
    onFilterChange(updatedFilters);
  };

  const handleMaxChange8 = (text8) => {
    const updatedFilters = { ...selectedFilters, max8: text8,
      youtubeSubscriber: `${selectedFilters.min8 || ""}-${text8 || ""}`,
     };
    onFilterChange(updatedFilters);
  };


  const handleCityToggle = (filter, city) => {
    const updatedFilters = { ...selectedFilters, [filter]: city };
    onFilterChange(updatedFilters);
  };
  

  const renderFilterOptions = filter => {
    const isFilterSelected =
      selectedFilters[filter] !== undefined && selectedFilters[filter] !== null;

    switch (filter) {
      case 'age':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }} >Select Age</Text>
            <CheckboxGroup
              selectedValues={selectedFilters[filter] || []} // Make sure it's an array
              onSelectionChange={values => handleFilterToggle(filter, values)}
              options={[
                { label: 'All', value: '0-90' },
                { label: '0-2', value: '0-2' },
                { label: '2-5', value: '2-5' },
                { label: '5-12', value: '5-12' },
                { label: '12-18', value: '12-18' },
                { label: '18-24', value: '18-24' },
                { label: '24-35', value: '24-35' },
                { label: '35-45', value: '35-45' },
                { label: '45-55', value: '45-55' },
                { label: '55+', value: '55-90' },
                // Add more options as needed
              ]}
            />
          </View>
        );
      case 'gender':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }} >Select Gender</Text>
            <CheckboxGroup
              selectedValues={selectedFilters[filter] || []} // Make sure it's an array
              onSelectionChange={values => handleFilterToggle(filter, values)}
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'LGBTQ', value: 'lgbtq' },
                { label: 'Others', value: 'others' }
              ]}
            />
          </View>
        );
      case 'phone':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }} >Select Phone And Email</Text>
            <CheckboxGroup
              selectedValues={selectedFilters[filter] || []} // Make sure it's an array
              onSelectionChange={values => handleFilterToggle(filter, values)}
              options={[

                { label: 'With Phone', value: 'With Phone' },
                { label: 'With Email', value: 'With Email' },
                { label: 'Without Phone', value: 'Without Phone' },
                { label: 'Without Email', value: 'Without Email' },
              ]}
            />
          </View>
        );
      case 'campaign':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }}>Select Campaign Type</Text>
            <CheckboxGroup
              selectedValues={selectedFilters[filter] || []} // Make sure it's an array
              onSelectionChange={values => handleFilterToggle(filter, values)}
              options={[
              
                { label: 'Paid Campaign', value: 'Paid Campaign' },
                { label: 'Review Campaign', value: 'Review Campaign' },
                { label: 'Barter Campaign', value: 'Barter Campaign' },
                { label: 'Ad Video Shoot Campaign', value: 'Ad Video Shoot Campaign' },

              ]}
            />
          </View>
        );
      case 'youtubeAccount':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }} >Select youtube option</Text>
            <CheckboxGroup
              selectedValues={selectedFilters[filter] || []} // Make sure it's an array
              onSelectionChange={values => handleFilterToggle(filter, values)}
              options={[

                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'No' },


              ]}
            />
          </View>
        );
      case 'youtubeSubscriber':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }} >Select  Subscribers  Range</Text>
            <TextInput style = {styles.input1}
              placeholder="min"
              value={selectedFilters['min8'] || ''}
              onChangeText={(text8) => handleMinChange8(text8)}
            />
            <TextInput style = {styles.input2}
              placeholder="max"
              value={selectedFilters['max8'] || ''}
              onChangeText={(text8) => handleMaxChange8(text8)}
            />
            <CheckboxGroup
              selectedValues={selectedFilters[filter] || []} // Make sure it's an array
              onSelectionChange={values => handleFilterToggle(filter, values)}
              options={[
                { label: 'All', value: '1-9000000000' },
                { label: 'Less than 1k', value: '1-1000' },
                { label: '1k-10k', value: '1000-10000' },
                { label: '10k-50k', value: '10000-50000' },
                { label: '50k-100k', value: '50000-100000' },
                { label: 'Above 100k+', value: '100000-900000000' }
              ]}
            />
          </View>
        );
      case 'followers':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }} >Select  Instgram Followers Range</Text>

              <TextInput style = {styles.input2}
              placeholder="min"
              value={selectedFilters['min7'] || ''}
              onChangeText={(text7) => handleMinChange7(text7)}
            />
            <TextInput style = {styles.input2}
              placeholder="max"
              value={selectedFilters['max7'] || ''}
              onChangeText={(text7) => handleMaxChange7(text7)}
            />
            <CheckboxGroup
              selectedValues={selectedFilters[filter] || []} // Make sure it's an array
              onSelectionChange={values => handleFilterToggle(filter, values)}
              options={[
                { label: 'All', value: '1-9000000000' },
                { label: 'Less than 2k', value: '1-2000' },
                { label: '2k-10k', value: '2000-10000' },
                { label: '10k-50k', value: '10000-50000' },
                { label: '50k-100k', value: '50000-100000' },
                { label: 'Above 100k+', value: '100000-900000000' }
              ]}
            />
          </View>
        );
      case 'state':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }} >Select State </Text>
            <CheckboxGroup
              selectedValues={selectedFilters[filter] || []} // Make sure it's an array
              onSelectionChange={values => handleFilterToggle(filter, values)}
              options={[
                { label: 'Andhra Pradesh', value: 'Andhra Pradesh' },
                { label: 'Arunachal Pradesh', value: 'Arunachal Pradesh' },
                { label: 'Assam', value: 'Assam' },
                { label: 'Bihar', value: 'Bihar' },
                { label: 'Chhattisgarh', value: 'Chhattisgarh' },
                { label: 'Goa', value: 'Goa' },
                { label: 'Gujarat', value: 'Gujarat' },
                { label: 'Haryana', value: 'Haryana' },
                { label: 'Himachal Pradesh', value: 'Himachal Pradesh' },
                { label: 'Jammu and Kashmir', value: 'Jammu and Kashmir' },
                { label: 'Jharkhand', value: 'Jharkhand' },
                { label: 'Karnataka', value: 'Karnataka' },
                { label: 'Kerala', value: 'Kerala' },
                { label: 'Madhya Pradesh', value: 'Madhya Pradesh' },
                { label: 'Maharashtra', value: 'Maharashtra' },
                { label: 'Manipur', value: 'Manipur' },
                { label: 'Meghalaya', value: 'Meghalaya' },
                { label: 'Mizoram', value: 'Mizoram' },
                { label: 'Nagaland', value: 'Nagaland' },
                { label: 'Odisha', value: 'Odisha' },
                { label: 'Punjab', value: 'Punjab' },
                { label: 'Rajasthan', value: 'Rajasthan' },
                { label: 'Sikkim', value: 'Sikkim' },
                { label: 'Tamil Nadu', value: 'Tamil Nadu' },
                { label: 'Telangana', value: 'Telangana' },
                { label: 'Tripura', value: 'Tripura' },
                { label: 'Uttar Pradesh', value: 'Uttar Pradesh' },
                { label: 'Uttarakhand', value: 'Uttarakhand' },
                { label: 'West Bengal', value: 'West Bengal' },
              ]}
            />
          </View>
        );
      case 'languages':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }} >Select Language </Text>
            <CheckboxGroup
              selectedValues={selectedFilters[filter] || []} // Make sure it's an array
              onSelectionChange={values => handleFilterToggle(filter, values)}
              options={[
                { label: 'English', value: 'English' },
                { label: 'Kannada', value: 'Kannada' },
                { label: 'Manipuri', value: 'Manipuri' },
                { label: 'Mizo', value: 'Mizo' },
                { label: 'Kokborok', value: 'Kokborok' },
                { label: 'Assamese', value: 'Assamese' },
                { label: 'Bhojpuri', value: 'Bhojpuri' },
                { label: 'Konkani', value: 'Konkani' },
                { label: 'Dogri', value: 'Dogri' },
                { label: 'Gujarati', value: 'Gujarati' },
                { label: 'Marathi', value: 'Marathi' },
                { label: 'Sindhi', value: 'Sindhi' },
                { label: 'Nepali', value: 'Nepali' },
                { label: 'Urdu', value: 'Urdu' },
                { label: 'Maithli', value: 'Maithli' },
                { label: 'Malayalam', value: 'Malayalam' },
                { label: 'Odia', value: 'Odia' },
                { label: 'Sanskrit', value: 'Sanskrit' },
                { label: 'Italian', value: 'Italian' },
                { label: 'Hindi', value: 'Hindi' },
                { label: 'Bengali', value: 'Bengali' },
                { label: 'Tamil', value: 'Tamil' },


                { label: 'Telugu', value: 'Telugu' },
                { label: 'Rajasthani', value: 'Rajasthani' },
                { label: 'Chhattisgarhi', value: 'Chhattisgarhi' },
                { label: 'Magahi', value: 'Magahi' },
                { label: 'Khortha/Khotta', value: 'Khortha/Khotta' },
                { label: 'Marwari', value: 'Marwari' },
                { label: 'Bundeli/Bundel khandi', value: 'Bundeli/Bundel khandi' },
                { label: 'Lamani/Lambadi', value: 'Lamani/Lambadi' },
                { label: 'Wagdi', value: 'Wagdi' },
                { label: 'Awadhi', value: 'Awadhi' },
                { label: 'Mewari', value: 'Mewari' },
                { label: 'Sadan/Sadri', value: 'Sadan/Sadri' },
                { label: 'Malvi', value: 'Malvi' },
                { label: 'Sambalpuri', value: 'Sambalpuri' },
                { label: 'Bagheli/Baghel Khandi', value: 'Bagheli/Baghel Khandi' },
                { label: 'Gondi', value: 'Gondi' },
                { label: 'Hara/Harauti', value: 'Hara/Harauti' },
                { label: 'Bhili/Bhilodi', value: 'Bhili/Bhilodi' },
                { label: 'Pahari', value: 'Pahari' },
                { label: 'Surgujia', value: 'Surgujia' },
                { label: 'Tulu', value: 'Tulu' },
                { label: 'Kurukh/Oraon', value: 'Kurukh/Oraon' },
                { label: 'Kumauni', value: 'Kumauni' },


                { label: 'Bagri', value: 'Bagri' },
                { label: 'Ahirani', value: 'Ahirani' },
                { label: 'Banjari', value: 'Banjari' },
                { label: 'Brajbhasha', value: 'Brajbhasha' },
                { label: 'Dhundhari', value: 'Dhundhari' },
                { label: 'Bodo/Boro', value: 'Bodo/Boro' },
                { label: 'Ho', value: 'Ho' },
                { label: 'Gojri/Gujjari/Gujar', value: 'Gojri/Gujjari/Gujar' },
                { label: 'Mundari', value: 'Mundari' },
                { label: 'Garo', value: 'Garo' },
                { label: 'Kangri', value: 'Kangri' },
                { label: 'Khasi', value: 'Khasi' },
                { label: 'Kachchhi', value: 'Kachchhi' },
                { label: 'Surjapuri', value: 'Surjapuri' },
                { label: 'Nimadi', value: 'Nimadi' },
                { label: 'Garhwali', value: 'Garhwali' },

                { label: 'Santali', value: 'Santali' },
                { label: 'Kui', value: 'Kui' },
                { label: 'Lushai/Mizo', value: 'Lushai/Mizo' },
                { label: 'Halabi', value: 'Halabi' },
                { label: 'Korku', value: 'Korku' },
                { label: 'Miri/Mishing', value: 'Miri/Mishing' },
                { label: 'Munda', value: 'Munda' },
                { label: 'Karbi/Mikir', value: 'Karbi/Mikir' },
                { label: 'Koya', value: 'Koya' },
                { label: 'Ao', value: 'Ao' },
                { label: 'Savara', value: 'Savara' },
                { label: 'Konyak', value: 'Konyak' },
                { label: 'Kharia', value: 'Kharia' },
                { label: 'Malto', value: 'Malto' },

                { label: 'Nissi/Dafla', value: 'Nissi/Dafla' },
                { label: 'Kashmiri', value: 'Kashmiri' },
                { label: 'Adi', value: 'Adi' },
                { label: 'Thado', value: 'Thado' },
                { label: 'Lotha', value: 'Lotha' },
                { label: 'Coorgi/Kodagu', value: 'Coorgi/Kodagu' },
                { label: 'Rabha', value: 'Rabha' },
                { label: 'Tangkhul', value: 'Tangkhul' },
                { label: 'Kisan', value: 'Kisan' },
                { label: 'Angami', value: 'Angami' },
                { label: 'Phom', value: 'Phom' },
                { label: 'Kolami', value: 'Kolami' },
                { label: 'Khond/Kondh', value: 'Khond/Kondh' },
                { label: 'Dimasa', value: 'Dimasa' },
                { label: 'Ladakhi', value: 'Ladakhi' },
                { label: 'Sema', value: 'Sema' },
              ]}
            />
          </View>
        );
      case 'category':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }}>Select Category</Text>
            <CheckboxGroup
              selectedValues={selectedFilters[filter] || []} // Make sure it's an array
              onSelectionChange={values => handleFilterToggle(filter, values)}
              options={[
                { label: 'Student', value: 'Student' },
                { label: 'Housewife', value: 'Housewife' },
                { label: 'Travel', value: 'Travel' },
                { label: 'Gaming', value: 'Gaming' },
                { label: 'Dance', value: 'Dance' },
                { label: 'Yoga', value: 'Yoga' },
                { label: 'Health & Fitness', value: 'Health & Fitness' },
                { label: 'Food', value: 'Food' },
                { label: 'Fashion', value: 'Fashion' },
                { label: 'LifeStyle', value: 'LifeStyle' },
                { label: 'Beauty', value: 'Beauty' },
                { label: 'Cosmetics', value: 'Cosmetics' },
                { label: 'Clothing', value: 'Clothing' },
                { label: 'Electronics', value: 'Electronics' },
                { label: 'DIY', value: 'DIY' },
                { label: 'Small Entrepreneurs', value: 'Small Entrepreneurs' },
                { label: 'Working class', value: 'Working class' },
                { label: 'Cooking', value: 'Cooking' },
                { label: 'Tech', value: 'Tech' },
                { label: 'Gadgets', value: 'Gadgets' },

                { label: 'Airlines/Aviation', value: 'Airlines/Aviation' },
                { label: 'Alternative Medicine', value: 'Alternative Medicine' },
                { label: 'Animals', value: 'Animals' },
                { label: 'Arts and Crafts', value: 'Arts and Crafts' },
                { label: 'Athletes', value: 'Athletes' },
                { label: 'Automation & Robotics', value: 'Automation & Robotics' },
                { label: 'Automotive', value: 'Automotive' },
                { label: 'Baby Care', value: 'Baby Care' },
                { label: 'Banking', value: 'Banking' },
                { label: 'Beauty and Makeup', value: 'Beauty and Makeup' },
                { label: 'Body Care', value: 'Body Care' },
                { label: 'Business/Making Money', value: 'Business/Making Money' },
                { label: 'Coffee, Tea & Beverages', value: 'Coffee, Tea & Beverages' },
                { label: 'Computer Software', value: 'Computer Software' },
                { label: 'Dentists', value: 'Dentists' },
                { label: 'Dermatologist', value: 'Dermatologist' },
                { label: 'Doctors', value: 'Doctors' },
                { label: 'Education', value: 'Education' },
                { label: 'Electronic and Technology', value: 'Electronic and Technology' },
                { label: 'Entertainment', value: 'Entertainment' },
                { label: 'Ethnic Wear', value: 'Ethnic Wear' },
                { label: 'Farming', value: 'Farming' },
                { label: 'Film, OTT and TV Series', value: 'Film, OTT and TV Series' },
                { label: 'Finance', value: 'Finance' },

                { label: 'Footwear', value: 'Footwear' },
                { label: 'Gambling & Casinos', value: 'Gambling & Casinos' },
                { label: 'Health & Wellness', value: 'Health & Wellness' },
                { label: 'Hair Care', value: 'Hair Care' },
                { label: 'Higher Education', value: 'Higher Education' },
                { label: 'Home & Decor', value: 'Home & Decor' },
                { label: 'Home & Garden', value: 'Home & Garden' },
                { label: 'Home & Kitchen', value: 'Home & Kitchen' },
                { label: 'Hospitality', value: 'Hospitality' },
                { label: 'Lingeries', value: 'Lingeries' },
                { label: 'Luxury Goods', value: 'Luxury Goods' },
                { label: 'Medical Practice', value: 'Medical Practice' },
                { label: 'Mental Health Care', value: 'Mental Health Care' },
                { label: 'Moms', value: 'Moms' },
                { label: 'Music', value: 'Music' },
                { label: 'Personal Care', value: 'Personal Care' },
                { label: 'Diet & Nutrition', value: 'Diet & Nutrition' },
                { label: 'Pets', value: 'Pets' },
                { label: 'Plus Size Fashion', value: 'Plus Size Fashion' },
                { label: 'Professional Training & Coaching', value: 'Professional Training & Coaching' },
                { label: 'Public Safety', value: 'Public Safety' },
                { label: 'Recreational Facilities and Services', value: 'Recreational Facilities and Service' },
                { label: 'Religious', value: 'Religious' },
                { label: 'Seniority (Old Age)', value: 'Seniority (Old Age)' },
                { label: 'Sexual Wellness & Sensuality', value: 'Sexual Wellness & Sensuality' },

                { label: 'Skin Care', value: 'Skin Care' },
                { label: 'Sneakers', value: 'Sneakers' },
                { label: 'Sports', value: 'Sports' },
                { label: 'Tobacco', value: 'Tobacco' },
                { label: 'Vehicles', value: 'Vehicles' },
                { label: 'Veterinary', value: 'Veterinary' },
                { label: 'Wine and Spirits', value: 'Wine and Spirits' },
                { label: 'Comedy', value: 'Comedy' },
                { label: 'Memes', value: 'Memes' },
                { label: 'Quotes & Texts', value: 'Quotes & Texts' },
                { label: 'Motivation', value: 'Motivation' },
                { label: 'Cars', value: 'Cars' },
                { label: 'Motorcycles', value: 'Motorcycles' },
                { label: 'Models', value: 'Models' },
                { label: 'Entrepreneurship', value: 'Entrepreneurship' },
                { label: 'Architecture & Interior', value: 'Architecture & Interior' },
                { label: 'Fan Accounts', value: 'Fan Accounts' },
                { label: 'Celebrity', value: 'Celebrity' },
                { label: 'Nails', value: 'Nails' },
                { label: 'Love & Romance', value: 'Love & Romance' },
                { label: 'Dogs', value: 'Dogs' },
                { label: 'Cats', value: 'Cats' },
                { label: 'Crypto & NFT', value: 'Crypto & NFT' },
                { label: 'Family/Parenting', value: 'Family/Parenting' },
                { label: 'Vagon', value: 'Vagon' },
              ]}
            />
          </View>
        );
      case 'city':
        return (
          <View style={styles.filterOption}>
            <TextInput
              style={styles.TextInput}
              placeholder="Enter City"
              value={selectedFilters[filter] || ''}
              onChangeText={text => handleCityToggle(filter, text)}
              editable={true}
            />
          </View>
        );
      case 'pincode':
        return (
          <View style={styles.filterOption}>
            <TextInput
              placeholder="Enter Pincode"
              value={selectedFilters[filter] || ''}
              onChangeText={text => handlePincodeChange(filter, text)}
            />
          </View>
        );
      case 'commercialsFor1InstagramReel':
       
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red' }}>Select Pricing Range Of Reel</Text>
            <TextInput style = {styles.input1}
              placeholder="min"
              value={selectedFilters['min'] || ''}
              onChangeText={(text) => handleMinChange(text)}
            />
            <TextInput style = {styles.input2}
              placeholder="max"
              value={selectedFilters['max'] || ''}
              onChangeText={(text) => handleMaxChange(text)}
            />
          </View>
        );
      case 'commercialsFor1InstagramStory':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }}>Select Pricing Range Of Story</Text>
            <TextInput style = {styles.input1}
              placeholder="min"
              value={selectedFilters['min1'] || ''}
              onChangeText={(text1) => handleMinChange1(text1)}
            />
            <TextInput style = {styles.input2}
              placeholder="max"
              value={selectedFilters['max1'] || ''}
              onChangeText={(text1) => handleMaxChange1(text1)}
            />
          </View>

        );
      case 'commercialsFor1InstagramPost':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }}>Select Pricing Range Of Post</Text>
            <TextInput style = {styles.input1}
              placeholder="min"
              value={selectedFilters['min2'] || ''}
              onChangeText={(text2) => handleMinChange2(text2)}
            />
            <TextInput style = {styles.input2}
              placeholder="max"
              value={selectedFilters['max2'] || ''}
              onChangeText={(text2) => handleMaxChange2(text2)}
            />
          </View>

        );
      case 'commercialsFor1YoutubeVideosDedicated':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }}>Select Pricing Range Of Post</Text>
            <TextInput style = {styles.input1}
              placeholder="min"
              value={selectedFilters['min3'] || ''}
              onChangeText={(text3) => handleMinChange3(text3)}
            />
            <TextInput style = {styles.input2}
              placeholder="max"
              value={selectedFilters['max3'] || ''}
              onChangeText={(text3) => handleMaxChange3(text3)}
            />
          </View>

        );
      case 'commercialsFor1YoutubeVideosIntegrated':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }}>Select Pricing Range Of Post</Text>
            <TextInput style = {styles.input1}
              placeholder="min"
              value={selectedFilters['min4'] || ''}
              onChangeText={(text4) => handleMinChange4(text4)}
            />
            <TextInput style = {styles.input2}
              placeholder="max"
              value={selectedFilters['max4'] || ''}
              onChangeText={(text4) => handleMaxChange4(text4)}
            />
          </View>

        );
      case 'commercialsFor1YoutubeVideosShortsDedicated':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }}>Select Pricing Range Of Post</Text>
            <TextInput style = {styles.input1}
              placeholder="min"
              value={selectedFilters['min5'] || ''}
              onChangeText={(text5) => handleMinChange5(text5)}
            />
            <TextInput style = {styles.input2}
              placeholder="max"
              value={selectedFilters['max5'] || ''}
              onChangeText={(text5) => handleMaxChange5(text5)}
            />
          </View>

        );
      case 'commercialsFor1YoutubeVideosShortsIntegrated':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }}>Select Pricing Range Of Post</Text>
              <TextInput style = {styles.input1}
              placeholder="min"
              value={selectedFilters['min6'] || ''}
              onChangeText={(text6) => handleMinChange6(text6)}
            />
            <TextInput style = {styles.input2}
              placeholder="max"
              value={selectedFilters['max6'] || ''}
              onChangeText={(text6) => handleMaxChange6(text6)}
            />
          </View>

        );
      case 'country':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }} >Select Your Country</Text>
            <CheckboxGroup
              selectedValues={selectedFilters[filter] || []} // Make sure it's an array
              onSelectionChange={values => handleFilterToggle(filter, values)}
              options={[
                
                { label: 'India', value: 'India' },
                { label: 'United States', value: 'United States' },
                { label: 'China', value: 'China' },
                { label: 'Japan', value: 'Japan' },
                { label: 'Germany', value: 'Germany' },
                { label: 'United Kingdom', value: 'United Kingdom' },
                { label: 'France', value: 'France' },
                { label: 'Russia', value: 'Russia' },
                { label: 'Canada ', value: 'Canada' },
                { label: 'Italy', value: 'Itly' },

                { label: 'Brazil', value: 'Brazil' },
                { label: 'South Korea', value: 'South Korea' },
                { label: 'Taiwan', value: 'Taiwan' },
                { label: 'Mexico', value: 'Mexico' },
                { label: 'Spain', value: 'Spain' },
                { label: 'Indonesia', value: 'Indonesia' },
                { label: 'Saudi Arabia', value: 'Saudi Arabia' },
                { label: 'Netherlands', value: 'Netherlands' },
                { label: 'Turkey', value: 'Turkey' },

                { label: 'Switzerland', value: 'Switzerland' },
                { label: 'Poland', value: 'Poland' },
                { label: 'Argentina', value: 'Argentina' },
                { label: 'Sweden', value: 'Sweden' },
                { label: 'Belgium', value: 'Belgium' },
                { label: 'Ireland', value: 'Ireland' },
                { label: 'Israel', value: 'Israel' },
                { label: 'United Arab Emirates', value: 'United Arab Emirates' },
                { label: 'Thailand', value: 'Thailand' },

                { label: 'Venezuela', value: 'Venezuela' },
                { label: 'Nigeria', value: 'Nigeria' },
                { label: 'Egypt', value: 'Egypt' },
                { label: 'Austria', value: 'Austria' },
                { label: 'Bangladesh', value: 'Bangladesh' },
                { label: 'Vietnam', value: 'Vietnam' },
                { label: 'South Africa', value: 'South Africa' },
                { label: 'Philippines', value: 'Philippines' },
                
                { label: 'Denmark', value: 'Denmark' },
                { label: 'Iran', value: 'Iran' },
                { label: 'Pakistan', value: 'Pakistan' },
                { label: 'Hong Kong', value: 'Hong Kong' },
                { label: 'Colombia', value: 'Colombia' },
                { label: 'Romania', value: 'Romania' },
                { label: 'Chile', value: 'Chile' },
                { label: 'Czechia', value: 'Czechia' },
                { label: 'Iraq', value: 'Iraq' },

                { label: 'Ethiopia', value: 'Ethiopia' },
                { label: 'DR Congo', value: 'DR Congo' },
                { label: 'Germany', value: 'Germany' },
                { label: 'Thailand', value: 'Thiland' },
                { label: 'Tanzania', value: 'Tanzania' },
                { label: 'France', value: 'France' },
                { label: 'Brazil', value: 'Brazil' },
                { label: 'Italy', value: 'Italy' },
                { label: 'Kenya', value: 'Kenya' },
                { label: 'Myanmar', value: 'Myanmar' },

                { label: 'Colombia', value: 'Colombia' },
                { label: 'Uganda', value: 'Uganda' },
                { label: 'Sudan', value: 'Sudan' },
                { label: 'Spain', value: 'Spain' },
                { label: 'Algeria', value: 'Algeria' },
                { label: 'Afghanistan', value: 'Afghanistan' },
                { label: 'Poland', value: 'Poland' },
                { label: 'Morocco', value: 'Morocco' },
                { label: 'Ukraine', value: 'Ukraine' },

                { label: 'Angola', value: 'Angola' },
                { label: 'Uzbekistan', value: 'uzbekistan' },
                { label: 'Yemen', value: 'Yemen' },
                { label: 'Peru', value: 'Peru' },
                { label: 'Malaysia', value: 'Malaysia' },
                { label: 'Ghana', value: 'Ghana' },
                { label: 'Mozambique', value: 'Mozambique' },
                { label: 'Nepal', value: 'Nepal' },
                { label: 'Madagascar', value: 'Madagascar' },
                { label: 'Côte dIvoire', value: 'Cote dIvoire' },

                { label: 'Cameroon', value: 'Cameroon' },
                { label: 'Niger', value: 'Niger' },
                { label: 'Senegal', value: 'Senegal' },
                { label: 'Guatemala', value: 'Guatemala' },
                { label: 'Somalia', value: 'Somalia' },
                { label: 'Ecuador', value: 'Ecuador' },
                { label: 'Chad', value: 'Chad' },
                { label: 'Kazakhstan', value: 'Kazakhstan' },
                { label: 'Zambia', value: 'Zambia' },
                { label: 'Malawi', value: 'Malawi' },

                { label: 'Sri Lanka', value: 'Sri Lanka' },
                { label: 'Syria', value: 'Syria' },
                { label: 'Burkina Faso', value: 'Burkina Faso' },
                { label: 'Mali', value: 'Mali' },
                { label: 'North Korea', value: 'North Korea' },
                { label: 'Australia', value: 'Australia' },
                { label: 'Guinea', value: 'Guinea' },
                { label: 'Zimbabwe', value: 'Zimbabwe' },
                { label: 'Cambodia', value: 'Cambodia' },

                { label: 'Laos', value: 'Laos' },
                { label: 'Sierra Leone', value: 'Sierra Leone' },
                { label: 'Switzerland', value: 'Switzerland' },
                { label: 'Togo', value: 'Togo' },
                { label: 'Belarus', value: 'Belarus' },
                { label: 'Tajikistan', value: 'Tajikistan' },
                { label: 'Hungary', value: 'Hungary' },
                { label: 'Portugal', value: 'Portugal' },
                { label: 'Papua New Guinea', value: 'Papua New Guinea' },
                { label: 'Greece', value: 'Greece' },

                { label: 'Azerbaijan', value: 'Azerbaijan' },
                { label: 'Czech Republic (Czechia)', value: 'Czech Republic (Czechia)' },
                { label: 'Honduras', value: 'Hondures' },
                { label: 'Sweden', value: 'Sweden' },
                { label: 'South Sudan', value: 'South Sudan' },
                { label: 'Cuba', value: 'Cuba' },
                { label: 'Dominican Republic', value: 'Dominican republic' },
                { label: 'Jordan', value: 'Jordan' },
                { label: 'Belgium', value: 'Belgium' },
                { label: 'Haiti', value: 'Haiti' },

                { label: 'Bolivia', value: 'Bolivia' },
                { label: 'Tunisia', value: 'Tunisia' },
                { label: 'Burundi', value: 'Burundi' },
                { label: 'Benin', value: 'Benin' },
                { label: 'Rwanda', value: 'Rwanda' },
                { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
                { label: 'Paraguay', value: 'Paraguay' },
                { label: 'Libya', value: 'Libya' },
                { label: 'Nicaragua', value: 'Nicaragua' },
                { label: 'Serbia', value: 'Serbia' },

                { label: 'Turkmenistan', value: 'Turkmenistan' },
                { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
                { label: 'Puerto Rico', value: 'Puerto Rico' },
                { label: 'Uruguay', value: 'Uruguay' },
                { label: 'Moldova', value: 'Moldova' },
                { label: 'Mongolia', value: 'Mongolia' },
                { label: 'Georgia', value: 'Georgia' },
                { label: 'Eritrea', value: 'Eritrea' },
                { label: 'Croatia', value: 'Croatia' },
                { label: 'Kuwait', value: 'Kuwait' },

                { label: 'Panama', value: 'Panama' },
                { label: 'Oman', value: 'Oman' },
                { label: 'Mauritania', value: 'Mauritania' },
                { label: 'New Zealand', value: 'New Zealand' },
                { label: 'Lebanon', value: 'Lebanon' },
                { label: 'State of Palestine', value: 'State of Palestine' },
                { label: 'Liberia', value: 'Liberia' },
                { label: 'Norway', value: 'Norway' },
                { label: 'Finland', value: 'Finland' },

                { label: 'Central African Republic', value: 'Central African Republic' },
                { label: 'Slovakia', value: 'Slovakia' },
                { label: 'Singapore', value: 'Singapore' },
                { label: 'Congo', value: 'Congo' },
                { label: 'El Salvador', value: 'El Salvador' },
                { label: 'Albania', value: 'Albania' },
                { label: 'Jamaica', value: 'Jamaica' },
                { label: 'Armenia', value: 'Armenia' },
                { label: 'Gambia', value: 'Gambia' },

                { label: 'Lithuania', value: 'Lithuania' },
                { label: 'Qatar', value: 'Qatar' },
                { label: 'Botswana', value: 'Botswana' },
                { label: 'Latvia', value: 'Latvia' },
                { label: 'North Macedonia', value: 'North Macedonia' },
                { label: 'Slovenia', value: 'Slovenia' },
                { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
                { label: 'Lesotho', value: 'Lesotho' },
                { label: 'Gabon', value: 'Gabon' },
                { label: 'Namibia', value: 'Namibia' },

                { label: 'Djibouti', value: 'Djibouti' },
                { label: 'Eswatini', value: 'Eswatini' },
                { label: 'Cyprus', value: 'Cyprus' },
                { label: 'Mauritius', value: 'Mauritius' },
                { label: 'Estonia', value: 'Estonia' },
                { label: 'Timor-Leste', value: 'Timor-Leste' },
                { label: 'Bahrain', value: 'Bahrain' },
                { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
                { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
                { label: 'Cabo Verde', value: 'Cabo Verde' },

                { label: 'Suriname', value: 'Suriname' },
                { label: 'Montenegro', value: 'Montenegro' },
                { label: 'Luxembourg', value: 'Luxembourg' },
                { label: 'Macao', value: 'Macao' },
                { label: 'Solomon Islands', value: 'Solomon Islands' },
                { label: 'Bhuta', value: 'Bhuta' },
                { label: 'Guyana', value: 'Guyana' },
                { label: 'Comoros', value: 'Comoros' },
                { label: 'Fiji', value: 'Fiji' },
                { label: 'Réunion', value: 'Réunion' },

                { label: 'Martinique', value: 'Martinique' },
                { label: 'Iceland', value: 'Iceland' },
                { label: 'Guadeloupe', value: 'Guadeloupe' },
                { label: 'Belize', value: 'Belize' },
                { label: 'Bahamas', value: 'Bahamas' },
                { label: 'Brunei', value: 'Brunei' },
                { label: 'Maldives', value: 'Maldives' },
                { label: 'Malta', value: 'Malta' },
                { label: 'Micronesia', value: 'Micronesia' },
                { label: 'Western Sahara', value: 'Western Sahara' },

                { label: 'Sint Maarten', value: 'Sint Maarten' },
                { label: 'Turks and Caicos', value: 'Turks and Caicos' },
                { label: 'Saint Kitts & Nevis', value: 'Saint Kitts & Nevis' },
                { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
                { label: 'Faeroe Islands', value: 'Faeroe Islands' },
                { label: 'Greenland', value: 'Greenland' },
                { label: 'Bermuda', value: 'Bermuda' },
                { label: 'Cayman Islands', value: 'Cayman Islands' },
                { label: 'Dominica', value: 'Dominica' },
                { label: 'Andorra', value: 'Andorra' },

                { label: 'Isle of Man', value: 'Isle of Man' },
                { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
                { label: 'U.S. Virgin Islands', value: 'U.S. Virgin Islands' },
                { label: 'St. Vincent & Grenadines', value: 'St. Vincent & Grenadines' },
                { label: 'Aruba', value: 'Aruba' },
                { label: 'Seychelles', value: 'Seychelles' },
                { label: 'Tonga', value: 'Tonga' },
                { label: 'Grenada', value: 'Grenada' },
                { label: 'Kiribati', value: 'Kiribati' },
                { label: 'Guam', value: 'Guam' },

                { label: 'Saint Lucia', value: 'Saint Lucia' },
                { label: 'Curaçao', value: 'Curaçao' },
                { label: 'Samoa', value: 'Samoa' },
                { label: 'Sao Tome & Principe', value: 'Sao Tome & Principe' },
                { label: 'Mayotte', value: 'Mayotte' },
                { label: 'Vanuatu', value: 'Vanuatu' },
                { label: 'French Guiana', value: 'French Guiana' },
                { label: 'French Polynesia', value: 'French Polynesia' },
                { label: 'New Caledonia', value: 'New Caledonia' },
                { label: 'Barbados', value: 'Barbados' },

                { label: 'Cook Islands', value: 'Cook Islands' },
                { label: 'Palau', value: 'Palau' },
                { label: 'Caribbean Netherland', value: 'Caribbean Netherland' },
                { label: 'British Virgin Islands', value: 'British Virgin Islands' },
                { label: 'Saint Martin', value: 'Saint Martin' },
                { label: 'Gibraltar', value: 'Gibraltar' },
                { label: 'San Marino', value: 'San Marino' },
                { label: 'Monaco', value: 'Monaco' },
                { label: 'Liechtenstein', value: 'Liechtenstein' },
                { label: 'Marshall Islands', value: 'Marshall Islands' },

                { label: 'American Samoa', value: 'American Samoa' },
                { label: 'Holy See', value: 'Holy See' },
                { label: 'Tokelau', value: 'Tokelau' },
                { label: 'Niue', value: 'Niue' },
                { label: 'Falkland Islands', value: 'Falkland Islands' },
                { label: 'Montserrat', value: 'Montserrat' },
                { label: 'Saint Helena', value: 'Saint Helena' },
                { label: 'Saint Pierre & Miquelon', value: 'Saint Pierre & Miquelon' },
                { label: 'Saint Barthelemy', value: 'Saint Barthelemy' },
                { label: 'Tuvalu', value: 'Tuvalu' },

                { label: 'Wallis & Futuna', value: 'Wallis & Futuna' },
                { label: 'Nauru', value: 'Nauru' },
                { label: 'Anguilla', value: 'Anguilla' },
                // Add more options as needed
              ]}
            />
          </View>

        );
        case 'platform':
        return (
          <View style={styles.filterOption}>
            <Text style={{ color: 'red', }} >Select Your Platform</Text>
            <CheckboxGroup
              selectedValues={selectedFilters[filter] || []} // Make sure it's an array
              onSelectionChange={values => handleFilterToggle(filter, values)}
              options={[
                { label: 'All', value: 'Instagram,Facebook,Youtube,TikTok' },
                { label: 'Instagram', value: 'Instagram' },
                { label: 'Facebook', value: 'Facebook' },
                { label: 'YouTube', value: 'Youtube' },
                { label: 'TikTok', value: 'TikTok' },
                { label: 'MX TakaTak', value: 'MX TakaTak' },
                { label: 'Chingari', value: 'Chingari' },
                { label: 'Josh', value: 'Josh' },
                { label: 'LinkedIn', value: 'LinkedIn' },
                { label: 'X (formarly known as twitter) ', value: 'X (formarly known as twitter)' },
                { label: 'Pinterest', value: 'Pinterest' },
                { label: 'Quora', value: 'Quora' },
                { label: 'Own Website', value: 'Own Website' },

                { label: 'Snapchat', value: 'Snapchat' },
                { label: 'Roposo', value: 'Roposo' },
                { label: 'ShareChat', value: 'ShareChat' },
                { label: 'Koo', value: 'Koo' },
                { label: '9GAG', value: '9GAG' },
                { label: 'Moj', value: 'Moj' },
                { label: 'Tumblr', value: 'Tumblr' },
                { label: 'Whatsapp', value: 'Whatsapp' },
                { label: 'Offline', value: 'Offline' },
                { label: 'Mitron', value: 'Mitron' },
                { label: 'Trell', value: 'Trell' },
                { label: 'Likee', value: 'Likee' },
                { label: 'Tiki', value: 'Tiki' },
                { label: 'Public', value: 'Public' },
                { label: 'Rizzle', value: 'Rizzle' },
                { label: 'Lumi', value: 'Lumi' },
                { label: 'Kutumb', value: 'Kutumb' },
                { label: 'Others', value: 'others' },
                // Add more options as needed
              ]}
            />
          </View>

        );
      default:
        return null;
    }
  };

  return (

    <View style={styles.container}>
      <Text style={styles.title}>Filters</Text>
      <ScrollView style={styles.scrollContainer}>
        {filters.map(filter => (
          <View key={filter.value} style={styles.filterItem}>
            <Text style={styles.filterLabel}>{filter.label}</Text>
            {renderFilterOptions(filter.value)}
          </View>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 5,
    elevation: 3,
    paddingRight: 1000,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  scrollContainer: {
    maxHeight: 450,


  },
  filterItem: {
    marginBottom: 10,
  },
  filterLabel: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  filterOption: {
    //  flexDirection: 'row', // Horizontl layout
    justifyContent: 'space-between',
    borderWidth: 3,
    borderColor: '#ccc',
    borderRadius: 10,
    maxHeight: 280,
  },
  TextInput: {
    borderWidth: 1,
    borderRadius: 5,
    color: 'black',
  },
  input1: {
    borderWidth: 2,
    borderRadius: 5,
    color: 'red',
    maxHeight: 25,
    justifyContent: 'space-between',
  },
  input2: {
    borderWidth: 0.5,
    borderRadius: 5,
    color: 'green',
    maxHeight: 25,
    justifyContent: 'space-between',
  },
  input3: {
    borderWidth: 0.5,
    borderRadius: 5,
    color: 'red',
    maxHeight: 60,
    marginBottom: 10,
  },
});

export default Filter;
