import { useState, useEffect } from 'react';
import { View, Text, TextInput, Button, StyleSheet, Dimensions } from 'react-native';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Config } from './.env.js';

const Login = ({ onToggle, onLogin, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('lastActivityTimestamp');
    navigate('/');
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const tokenExpiration = localStorage.getItem('tokenExpiration');
      if (tokenExpiration && Date.now() > parseInt(tokenExpiration, 10)) {
        // Token is expired, perform logout
        handleLogout();
        return;
      }

      // Token is not expired, proceed with login
      onLogin(storedToken);
      setIsLoggedIn(true);
      navigate('/dashboard');
    }
  }, [onLogin, setIsLoggedIn, navigate, handleLogout]);

  useEffect(() => {
    // Check for last activity timestamp in localStorage
    const lastActivityTimestamp = localStorage.getItem('lastActivityTimestamp');
    const inactivityThreshold = 300000; // 5 minutes in milliseconds

    // If last activity timestamp is available, check elapsed time
    if (lastActivityTimestamp) {
      const elapsedTime = Date.now() - parseInt(lastActivityTimestamp, 10);

      // If elapsed time exceeds the threshold, trigger logout
      if (elapsedTime > inactivityThreshold) {
        handleLogout();
        return;
      }
    }

    // Update last activity timestamp on every user interaction
    const updateLastActivityTimestamp = () => {
      localStorage.setItem('lastActivityTimestamp', Date.now().toString());
    };

    // Attach event listeners for user interaction
    document.addEventListener('mousemove', updateLastActivityTimestamp);
    document.addEventListener('keydown', updateLastActivityTimestamp);

    // Clean up event listeners when the component unmounts
    return () => {
      document.removeEventListener('mousemove', updateLastActivityTimestamp);
      document.removeEventListener('keydown', updateLastActivityTimestamp);
    };
  }, [setIsLoggedIn, navigate, handleLogout]);

  const handleAuth = async () => {
    try {
      const response = await axios.post(
        `${Config.API_URL}/login`,
        {
          username,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Login Response:', response.data);
      const { token } = response.data;
      if (token) {
        const tokenExpiration = Date.now() + 360000; // 1 hour in milliseconds
        localStorage.setItem('token', token);
        localStorage.setItem('tokenExpiration', tokenExpiration.toString());
        onLogin(token);
        setIsLoggedIn(true);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Login error:', error.response); 
    }
  };

  const windowWidth = Dimensions.get('window').width;
  return (
    <View
      style={[
        styles.container,
        windowWidth < 500 && styles.halfWindowContainer,
      ]}
    >
      <Text style={styles.title}>Login</Text>
      <TextInput
        style={styles.input}
        placeholder="Username"
        value={username}
        onChangeText={setUsername}
      />
      <TextInput
        style={styles.input}
        placeholder="Password"
        secureTextEntry
        value={password}
        onChangeText={setPassword}
      />
      <Button title={'Login'} onPress={handleAuth} />

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 8,
    paddingHorizontal: 20,
    elevation: 3,
    margin: 150,
  },
  halfWindowContainer: {
    width: '50%',
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
  input: {
    width: 250,
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginBottom: 10,
    padding: 5,
  },
});

export default Login;
