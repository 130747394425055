// Checkbox.js (Create this file)
//import { useState } from 'react';
import React, {useState} from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';

const Checkbox = ({ label, value, isSelected, onCheckboxChange }) => {
  const [checked, setChecked] = useState(isSelected);

  const handlePress = () => {
    setChecked(!checked);
    onCheckboxChange(value);
  };

  return (
    <TouchableOpacity onPress={handlePress}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View
          style={{
            height: 20,
            width: 20,
            borderWidth: 2,
            borderColor: '#000',
            borderRadius: 5,
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 5,
            marginBottom:10,
            backgroundColor: checked ? 'red' : 'transparent',
          }}
        >
          {checked && <Text style={{ color: '#fff', fontSize: 12 }}>✓</Text>}
        </View>
        <Text>{label}</Text>
      </View>
    </TouchableOpacity>
  );
};

const CheckboxGroup = ({ options, selectedValues, onSelectionChange }) => {
  const handleCheckboxChange = value => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter(val => val !== value)
      : [...selectedValues, value];
    onSelectionChange(updatedValues);
  };

  return (
    <ScrollView>
      {options.map(option => (
        <Checkbox
          key={option.value}
          label={option.label}
          value={option.value}
          isSelected={selectedValues.includes(option.value)}
          onCheckboxChange={handleCheckboxChange}
        />
      ))}
    </ScrollView>
  );
};

export default CheckboxGroup;
