import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import PrivateRoute from './PrivateRoute'
import Dshboard from './youtubeDashboard';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const handleLogin = newToken => {
   // console.log('New Token:', newToken);
    setToken(newToken);
    setIsLoggedIn(true);
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/dashboard"
          element={<PrivateRoute element={<Dashboard token={token} />} isLoggedIn={isLoggedIn} />}
        />
        <Route
          path="/youtubeDashboard"
          element={<PrivateRoute element={<Dshboard token={token} />} isLoggedIn={isLoggedIn} />}
        />
        <Route
          path="/"
          element={<Login onLogin={handleLogin} setIsLoggedIn={setIsLoggedIn} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
