import  {useState, useEffect} from 'react';
import {
  View,
  Text,
  Button,
  StyleSheet,
  Dimensions,
  ScrollView,
  Alert,
} from 'react-native';
import Filter from './Filter'; // Import the Filter component
import axios from 'axios';
import {Config} from './.env.js'
import { clearToken } from './auth';
import { useNavigate } from 'react-router-dom';
//import { useNavigation } from '@react-navigation/native';


const Dashboard = ({token}) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    clearToken();
    window.location.reload(); // Refresh the page after clearing token
  };
  if (!token) {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Please log in to access the dashboard.</Text>
      </View>
    );
  }
  const [selectedFilters, setSelectedFilters] = useState({
    age: '',
    gender: '',
    followers: '',
    state: '',
    city: '',
    pincode: '',
    category: '',
    phone: '',
    languages: '',
    campaign: '',
    youtubeSubscriber: '',
    youtubeAccount: '',
    commercialsFor1InstagramReel: '',
    commercialsFor1InstagramStory: '',
    commercialsFor1InstagramPost: '',
    commercialsFor1YoutubeVideosIntegrated: '',
    commercialsFor1YoutubeVideosDedicated: '',
    commercialsFor1YoutubeVideosShortsIntegrated: '',
    commercialsFor1YoutubeVideosShortsDedicated: '',
    platform: '',
    country: '',
  });
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadStatus, setUploadStatus] = useState(null);
  const [downloadedFileName, setDownloadedFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get('window').width,
  );
  useEffect(() => {
    const handleDimensionsChange = ({window}) => {
      setWindowWidth(window.width);
    };
    Dimensions.addEventListener('change', handleDimensionsChange);

    return () => {
      Dimensions.removeEventListener('change', handleDimensionsChange);
    };
  }, []);
  const filters = [
    {label: 'Country', value: 'country'},
    {label: 'Platform', value: 'platform'},
    {label: 'Age', value: 'age'},
    {label: 'Gender', value: 'gender'},
    {label: 'Instagram Followers Range', value: 'followers'},
    {label: 'State', value: 'state'},
    {label: 'City', value: 'city'},
    {label: 'Pincode', value: 'pincode'},
    {label: 'Category', value: 'category'},
    {label: 'Phone Options', value: 'phone'},
    {label: 'Languages', value: 'languages'},
    {label: 'Campaign Type', value: 'campaign'},
    {label: 'Youtube Subscribers', value: 'youtubeSubscriber'},
    {label: 'Youtube Account', value: 'youtubeAccount'},
    {label: 'Commercials for 1 Instagram Reel', value: 'commercialsFor1InstagramReel'},
    {label: 'Commercials for 1 Instagram Story', value: 'commercialsFor1InstagramStory'},
    {label: 'Commercials for 1 Instagram Post', value: 'commercialsFor1InstagramPost'},
    {label: 'Commercials for 1 Youtube Videos(Integrated)', value: 'commercialsFor1YoutubeVideosIntegrated'},
    {label: 'Commercials for 1 Youtube Videos(Dedicated)', value: 'commercialsFor1YoutubeVideosDedicated'},
    {label: 'Commercials for 1 Youtube Videos Shorts(Integrated)', value: 'commercialsFor1YoutubeVideosShortsIntegrated'},
    {label: 'Commercials for 1 Youtube Videos Shorts(Dedicated)', value: 'commercialsFor1YoutubeVideosShortsDedicated'},
    
    // Add more filter options...
  ]; // Your list of filters

  /*const handleFilterChange = updatedFilters => {
    setSelectedFilters(prevFilters => ({
      ...prevFilters,
      ...updatedFilters,
    }));
  };*/
  const handleFilterChange = (updatedFilters) => {
    //console.log({updatedFilters})
    setSelectedFilters(updatedFilters);
  };
  const handleSwitchToYoutubeDashboard = () => {
    // Navigate to the YouTube dashboard screen
    navigate('/youtubeDashboard');// Change 'YoutubeDashboard' to the actual name of your YouTube dashboard screen
  };


  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const response = await axios.post(
        `${Config.API_URL}/api/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log('Upload response:', response);
       // res.send('data uploaded')
      // console.log('Upload response:', response.data);
      // setUploadedFileName(response.data.fileName);
      if (response.data === 'data uploaded') {
        setUploadedFileName(response.data.fileName);
        setUploadStatus('Data uploaded successfully!');
      }
    } catch (error) {
      console.error('Upload error:', error);
      setUploadStatus('Error uploading data. Please try again.');
    }
  };
  const handleFileChange = event => {
    const selected = event.target.files[0];
    setSelectedFile(selected);
  };
  const handleDownload = async () => {
    console.log({selectedFilters});
    try {
      const queryParams = {
        age: selectedFilters.age,
        gender: selectedFilters.gender,
        followers: selectedFilters.followers,
        state: selectedFilters.state,
        city: selectedFilters.city,
        pincode: selectedFilters.pincode,
        category: selectedFilters.category,
        phone: selectedFilters.phone,
        languages: selectedFilters.languages,
        campaign: selectedFilters.campaign,
        youtubeAccount: selectedFilters.youtubeAccount,
        youtubeSubscriber: selectedFilters.youtubeSubscriber,
        commercialsFor1InstagramReel: selectedFilters.min+'-'+selectedFilters.max,
        commercialsFor1InstagramStory: selectedFilters.commercialsFor1InstagramStory,
        commercialsFor1InstagramPost: selectedFilters.commercialsFor1InstagramPost,
        commercialsFor1YoutubeVideosIntegrated: selectedFilters.commercialsFor1YoutubeVideosIntegrated,
        commercialsFor1YoutubeVideosShortsDedicated: selectedFilters.commercialsFor1YoutubeVideosShortsDedicated,
        commercialsFor1YoutubeVideosShortsIntegrated: selectedFilters.commercialsFor1YoutubeVideosShortsIntegrated,
        commercialsFor1YoutubeVideosDedicated: selectedFilters.commercialsFor1YoutubeVideosShortsIntegrated,
        platform: selectedFilters.platform,
        country: selectedFilters.country,
      };

      const queryParamsString = new URLSearchParams(queryParams).toString();

      const response = await axios.get(
        `${Config.API_URL}/api/download?${queryParamsString}`,
        {
          responseType: 'blob', // Use responseType 'blob' to handle binary data
        },
      );

      const blob = new Blob([response.data], {type: 'text/csv'});
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor element
      const a = document.createElement('a');
      a.href = url;
      a.download = 'filtered_data.csv';
      a.click();

      URL.revokeObjectURL(url);

      setDownloadedFileName('filtered_data.csv');
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  
  return (
    <View style={styles.container}>
  
      <Text style={styles.title}>Dashboard</Text>
      
      <View style ={styles.Logout}>
      <Button title="Logout" onPress={handleLogout} />
      </View>
      <View style = {styles.youtube}>
      <Text>Instagram Dasboard</Text>
      <Button title = "switch to youtube dashboard" onPress={handleSwitchToYoutubeDashboard} />
      </View>
      <View style={styles.uploadDownloadContainer}>
        {windowWidth > 500 ? (
          <View style={styles.upload}>
            <input type="file" accept=".csv" onChange={handleFileChange} />
            <Button title="Upload" onPress={handleUpload} />
            <Text>Uploaded File: {uploadedFileName}</Text>
            <Text style={styles.uploadStatus}>{uploadStatus}</Text>
          </View>
        ) : null}
        <View style={styles.download}>
          <Button title="Download" onPress={handleDownload} />
          <Text>Downloaded File: {downloadedFileName}</Text>
        </View>
      </View>
      <View style={styles.filter}>
      <Text style={styles.uploadStatus}>{uploadStatus}</Text>
        <Filter
          filters={filters}
          selectedFilters={selectedFilters}
          onFilterChange={handleFilterChange}
        />
       
      </View>
   
      
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadDownloadContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    marginBottom: 5,
  },
  upload: {
    width: '48%',
    marginBottom: 1,
  },
  download: {
    width: '48%',
    marginTop: 20,
  },
  Filter: {
    //flex: 1,
    width: '60%',
    paddingHorizontal: 10,
  },
  Logout:{
    paddingLeft:1090,
    //paddingVertical: 20,
    //paddingHorizontal: 16,
    borderRadius: 10,
    marginTop:-15,
    
  },
  youtube:{
  paddingRight: 1130,
    //paddingVertical: 20,
    //paddingHorizontal: 16,
    borderRadius: 10,
    marginTop:-15,
    
  },
  title:{
    fontSize:25,
  }
});

export default Dashboard;
